import ModalMui from '@mui/material/Modal';
import styled from 'styled-components';

import { media } from '../../../styles/media';
import { Colors } from '../../../styles/colors';
import { CloseIcon } from '../Icons';
import { useCallback, useEffect } from 'react';

const KEY_NAME_ESC = 'Escape';
const KEY_EVENT_TYPE = 'keyup';

// https://joaoportela.com/blog/animate-styled-radix-primitives-framer-motion
// export const ModalOverlay = styled(Dialog.Overlay)`
//   background: rgba(0 0 0 / 0.5);
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   display: grid;
//   place-items: center;
//   overflow-y: auto;
// `;

// ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
// ${({ hasShadow }) => hasShadow && `box-shadow: 0px -4px 20px 0px rgba(0,0,0,0.03);`}
// ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
// export const ModalContent = styled(Dialog.Content)`
//   min-width: 300px;
//   background: white;
//   padding: 30px;
//   border-radius: 4px;
//   ${media.from_tablet`
//      padding: 30px;
//   `}
// `;

export const Modal = ({
  isVisible,
  setIsVisible,
  children,
}: {
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
  children: React.ReactNode;
}) => {
  const handleEscKey = useCallback(
    (event: any) => {
      if (event.key === KEY_NAME_ESC) {
        setIsVisible(false);
      }
    },
    [setIsVisible]
  );
  useEffect(() => {
    document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

    return () => {
      document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
    };
  }, [handleEscKey]);

  return (
    <>
      {isVisible ? (
        <ModalOverlay onClick={() => setIsVisible(false)}>
          <ModalContent onClick={(event) => event.stopPropagation()}>
            <CloseButton onClick={() => setIsVisible(false)}>
              <CloseIcon size={3} />
            </CloseButton>
            {children}
          </ModalContent>
        </ModalOverlay>
      ) : null}
    </>
  );
};

export const ModalOverlay = styled.div`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  background-color: ${Colors.ContrastOverlay};
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: ${Colors.White};
  margin: 0;
  padding: 16px;
  border: 1px solid ${Colors.Contrast};
  width: 96%;
  max-width: 900px;
  position: relative;
  border-radius: 8px;
  ${media.from_tablet`
    width: 80%; 
  `}

  ${media.from_laptop`
    width: 70%; 
  `}

${media.from_large_laptop`
    width: 65%; 
  `}
`;

const CloseButton = styled.button`
  position: absolute;
  top: -15px;
  right: -15px;
  background-color: ${Colors.White};
  border-radius: 50%;
  z-index: 1;
`;

export const ModalVersionTwo = ({
  isVisible,
  setIsVisible,
  children,
}: {
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
  children: React.ReactNode;
}) => {
  return (
    <ModalMui
      open={isVisible}
      onClose={() => setIsVisible(false)}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <>{children}</>
    </ModalMui>
  );
};
